import React, { useEffect, useState } from 'react';
import { Input, Button, Carousel } from 'antd';
import { connect } from 'react-redux';
import styles from './index.less';
import DsQRCode from 'components/DsQRCode';
import phoneimg from 'assets/banner/supplier/phoneimg.png';
import phone_2_img from 'assets/banner/supplier/phone_2.png';
import agencyEmail from 'assets/banner/supplier/agencyEmail.png';
import agencyEmail_2 from 'assets/banner/supplier/agencyEmail_2.png';
import { agencyUserMap, shipMethod } from 'utils/bannerWhiteList';
import { Post, Get } from 'utils/request';
import Banner from 'routes/Order/RutBanner';
import init from 'react-intl-universal';
import { CommonDataCollect } from 'utils/utils'

const data =
    [
        { "Seller Name": "Laura", "rules": "Seller", "Email": "laura@dserspro.com", "WhatsApp": "+8615775883947", "desc": "This is Laura.Always online, serious, fast, sincere service for you.", "pic name": "许塽.jpg", "销售名 ": "许塽", "dsec byte count": 168, "image": 'https://img.dsers.com/shopify/sales/xs.jpg' },
        { "Seller Name": "Ciel", "rules": "Seller", "Email": "ciel@dserspro.com", "WhatsApp": "+8617800783063", "desc": "Reliable, trustworthy. This is Ciel. Your time and effort deserve to be matched with Ciel's prowess.", "pic name": "杨琳琳.png", "销售名 ": "杨琳琳", "dsec byte count": 231, "image": 'https://img.dsers.com/shopify/sales/yll.png' },
        { "Seller Name": "Nora", "rules": "Seller", "Email": "cherry@dserspro.com", "WhatsApp": "+8615626428810", "desc": "Aims to promise economical price, reliable shipping . Look forward to cooperating with you!", "pic name": "林晓霞.jpg", "销售名 ": "林晓霞", "dsec byte count": 337, "image": 'https://img.dsers.com/shopify/sales/lxx.jpg' },
        { "Seller Name": "Chloe", "rules": "leader", "Email": "zhouxiaoqing@dserspro.com", "WhatsApp": "+8618157360759", "desc": "Familiar with dropshipping business process. Service purposes: find and meet your demands carefully.", "pic name": "周晓清.jpg", "销售名 ": "周晓清", "dsec byte count": 302, "image": 'https://img.dsers.com/shopify/sales/zxq.jpg' },
        { "Seller Name": "Kevin", "rules": "Seller", "Email": "kevin1@dserspro.com", "WhatsApp": "+8619181928451", "desc": "Focus on supply products for Brazil, Europe, US. Factory price with fast shipping.", "pic name": "李磊.png", "销售名 ": "李磊", "dsec byte count": 97, "image": 'https://img.dsers.com/shopify/sales/ll.png' },
        { "Seller Name": "Bess", "rules": "Seller", "Email": "bess@dserspro.com", "WhatsApp": "+8618328573251", "desc": "Chinese supplier, focusing on dropshipping 3 years of experience, 24 hours online.", "pic name": "张晓玲.jpg", "销售名 ": "张晓玲", "dsec byte count": 120, "image": 'https://img.dsers.com/shopify/sales/zxl.jpg' },
        { "Seller Name": "Anchor", "rules": "Seller", "Email": "anchor@dserspro.com", "WhatsApp": "+8613065808221", "desc": "DSers Mass Supplier,professional agent team, welcome to consult any DSers and products related questions.", "pic name": "刘尹琴.jpg", "销售名 ": "刘尹琴", "dsec byte count": 105, "image": 'https://img.dsers.com/shopify/sales/lyq.jpg' },
        { "Seller Name": "David", "rules": "Seller", "Email": "david@dserspro.com", "WhatsApp": "+8615700238570", "desc": "This is David, I can solve all your problems! In the meantime I can talk to you about China or other.", "pic name": "吴大伟.jpg", "销售名 ": "吴大伟", "dsec byte count": 101, "image": 'https://img.dsers.com/shopify/sales/lyq.png' },
        { "Seller Name": "Alisa", "rules": "Seller", "Email": "alisa@dserspro.com", "WhatsApp": "+8615310808994", "desc": "Alisa from Dsers Supply providing supply and customized service. Feel free to leave your requests.", "pic name": "何雪莲.png", "销售名 ": "何雪莲", "dsec byte count": 229, "image": 'https://img.dsers.com/shopify/sales/hxl.png' },
        { "Seller Name": "Keith", "rules": "leader", "Email": "daihanzhao@dserspro.com", "WhatsApp": "+8618602874120", "desc": "24 hours on duty for you! I am a professional with four years of experience of dropshipping mass supply business!", "pic name": "戴含照.jpg", "销售名 ": "戴含照", "dsec byte count": 113, "image": 'https://img.dsers.com/shopify/sales/xd.jpg' },
        { "Seller Name": "April", "rules": "Seller", "Email": "april@dserspro.com", "WhatsApp": "+8613158796305", "desc": "I'm April.I will provide you with high quality products at low prices and give you the best service!", "pic name": "胡诗雨.jpg", "销售名 ": "胡诗雨", "dsec byte count": 124, "image": 'https://img.dsers.com/shopify/sales/hsy.jpg' },
        { "Seller Name": "Jessica", "rules": "Seller", "Email": "jessica@dserspro.com", "WhatsApp": "+8615184475253", "desc": "I am Jessica. I will serve you wholeheartedly , look forward to cooperating with you，my friend !", "pic name": "周廷婷.jpg", "销售名 ": "周廷婷", "dsec byte count": 190, "image": 'https://img.dsers.com/shopify/sales/zyt.jpg' },
        { "Seller Name": "Duan", "rules": "Seller", "Email": "duanyanran@dsers.com", "WhatsApp": "+8618308195406", "desc": "Best, fastest, sincerest, and always ready for your request！", "pic name": "段嫣然.jpg", "销售名 ": "段嫣然", "dsec byte count": 22, "image": 'https://img.dsers.com/shopify/sales/dyr.jpg' },
        { "Seller Name": "Sophia", "rules": "leader", "Email": "zhanglu@dserspro.com", "WhatsApp": "+8618384217470", "desc": "Hi,my dropshipping friends, give me your requests, Dsers will do the best to make you satisfied.", "pic name": "张露.jpg", "销售名 ": "张露", "dsec byte count": 96, "image": 'https://img.dsers.com/shopify/sales/zl.jpg' }
    ]
data.sort(()=>{
    return Math.random() - 0.5
})
function index({ userInfo }) {

    const [cardQrCode, setCardQrCode] = useState({})
    const [showMask, setShowMask] = useState(false)
    const [maskShow, setMaskShow] = useState(false)
    const [cardQrCode_2, setCardQrCode_2] = useState()
    const [showMask_2, setShowMask_2] = useState(false)

    let timeout = null

    useEffect(()=>{
        CommonDataCollect({
            event_type:'agency',
            action:'enterAgencyPage'
        })
    },[])

    const saleInfo = agencyUserMap.concat(shipMethod).filter(item => {
        return item === userInfo.user_id
    })


    if (!saleInfo[0]) {
        window.location.href = '/app/'
        return null
    }

    const handleCardPhone = (data, type) => {
        return () => {
            setCardQrCode(data)
            setShowMask(type)
        }
    }

    const handleLeaderCardPhone = (data, type) => {
        return () => {
            setCardQrCode_2(data)
            setShowMask_2(type)
        }
    }

    const handleEmail = (email) => {
        return () => {
            window.location.href = `mailto:${email}`
        }
    }

    return (
        <div className={styles.agencyContainer}>
            <div className={styles.title}>{init.get('supplier_optimizer.agency_2')}</div>
            <div className={styles.text}>{init.getHTML('supplier_optimizer.agencySub')}</div>
            <div className={styles.agencyDescribe}>
                <div className={styles.leftBox}>
                    <div>{init.get('supplier_optimizer.agency')}</div>
                    <div>{init.get('supplier_optimizer.agencytext')} </div>
                    <div><span>CHEAPER</span><span>FASTER</span><span>RELIABLE</span></div>
                    <a href='https://www.dsers.com/blog/dsers-mass-supplier-program/' target='_blank'>{init.get('supplier_optimizer.learnmore')}</a>
                </div>
                <div className={styles.rightBox} >
                    <div className={styles.mask} style={showMask_2 ? {background: 'rgba(0,0,0,0.5000)'} : null}>
                        <div className={styles.inqrcode} style={showMask_2 ? { transform: 'translateY(0)' } : null}>
                            <div style={{ margin: '0', padding: '0', width: '100%', height: '32px', marginTop: '5px' }}>
                                <div style={{ fontSize: '12px', margin: '0', padding: '0', textAlign: 'center', height: '13px', color: "black" }}>{cardQrCode_2 && cardQrCode_2['Seller Name']}</div>
                                <div style={{ fontSize: '12px', margin: '0', padding: '0', transform: 'scale(0.9)', textAlign: 'center' }}>whatsApp contact</div>
                            </div>
                            <div>
                                {showMask_2 ? <DsQRCode text={'https://wa.me/' + cardQrCode_2?.WhatsApp} width='60' name='qrcode' /> : null}
                            </div>
                        </div>
                    </div>
                    <Carousel autoplay>
                        {
                            data.map((item, index) => {
                                if (item.rules === 'leader') {
                                    return (
                                        <div className='box'>
                                            <img className='saleImg' src={item.image} alt="" />
                                            <div className='saleName'>{item['Seller Name']}  <span>({item.WhatsApp.slice(3)})</span></div>
                                            <div className='saleEmail' title={item.Email}>{item.Email}</div>
                                            <div className='saleText' title={init.get(`supplier_optimizer.${item['Seller Name']}`)}>{init.get(`supplier_optimizer.${item['Seller Name']}`)}</div>
                                            <div className='saleIcon'><i onClick={handleEmail(item.Email)} title={item.Email}  alt="" class="material-icons notranslate notranslate">email</i> <div className='iconLine'></div> <img title={item.WhatsApp} onMouseEnter={handleLeaderCardPhone(item, true)} onMouseLeave={handleLeaderCardPhone(item, false)} src={phoneimg}/></div>
                                        </div>
                                    )
                                }
                            })
                        }
                    </Carousel>
                </div>
            </div>
            <Banner agencyUserMap={agencyUserMap} shipMethod={shipMethod} userId={userInfo.user_id}></Banner>
            <div className={styles.agencyTeam}>
                <div className={styles.title}>{init.get('supplier_optimizer.agenct')}</div>
                <div className={styles.card}>
                    {
                        data.map((item, key) => {
                           if(item.rules === 'Seller'){
                            return (
                                <div className={styles.cardItem} key={item['Seller Name']}>
                                    <div className={styles.mask} style={showMask && cardQrCode['Seller Name'] === item['Seller Name'] ? { background: 'rgba(0,0,0,0.5000)' } : null}>
                                        <div className={styles.inqrcode} style={showMask && cardQrCode['Seller Name'] === item['Seller Name'] ? { transform: 'translateY(0)' } : null}>
                                            <div style={{ margin: '0', padding: '0', width: '100%', height: '32px', marginTop: '5px' }}>
                                                <div style={{ fontSize: '12px', margin: '0', padding: '0', textAlign: 'center', height: '13px', color: "black" }}>{item['Seller Name']}</div>
                                                <div style={{ fontSize: '12px', margin: '0', padding: '0', transform: 'scale(0.9)', textAlign: 'center' }}>whatsApp contact</div>
                                            </div>
                                            <div>
                                                <DsQRCode text={'https://wa.me/' + item.WhatsApp} width='60' name='qrcode' />
                                            </div>

                                        </div>
                                    </div>
                                    <div className={styles.cardSub}>
                                        <div className={styles.topInfo}>
                                            <img src={item.image} alt="" />
                                            <div className={styles.infoName}>
                                                <div>{item['Seller Name']}</div>
                                                <div>{item.Email}</div>
                                            </div>
                                        </div>
                                        <div className={styles.infoText}>
                                            {init.get(`supplier_optimizer.${item['Seller Name']}`)}
                                        </div>
                                        <div className={styles.saleIcon}><i onClick={handleEmail(item.Email)} title={item.Email}  alt=""  class="material-icons notranslate notranslate">email</i><div className={styles.iconLine}></div> <img src={phoneimg} title={item.WhatsApp} onMouseEnter={handleCardPhone(item, true)} onMouseLeave={handleCardPhone(item, false)}/></div>
                                    </div>
                                </div>
                            )
                           }
                        })
                    }

                </div>
            </div>
        </div>
    )
}

export default connect(({ login }) => {
    return {
        userInfo: login.userInfo
    };
})(index);